import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import cogoToast from 'cogo-toast';

import { DATE_TIME_FORMAT } from '@config';
import { AsyncDispatch } from '@redux/types';
import BackButton from '@components/BackButton';
import {
    getAppUser,
    getAppUserBookings,
} from '@redux/modules/app-users/selectors';
import {
    fetchAppUser,
    deleteAppUser,
    updateAppUser,
} from '@redux/modules/app-users/actions';

import styles from './styles.module.css';
import AppUserForm from './Form';

export const AppUserDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AsyncDispatch>();
    const appUser = useSelector(getAppUser(id || ''));
    const appUserBookings = useSelector(getAppUserBookings(id || ''));
    const history = useHistory();

    useEffect(() => {
        if (!id) {
            return;
        }
        dispatch(fetchAppUser(id));
    }, [dispatch, id]);

    const onSubmit = useCallback(
        (values) => {
            if (!id) {
                return;
            }
            dispatch(updateAppUser({ id, values }))
                .then((user) => {
                    if (user) {
                        cogoToast.success('Пользователь обновлен', {
                            position: 'top-right',
                            hideAfter: 5,
                        });
                        history.push(`/dashboard/app-users`);
                    }
                })
                .catch(() => {
                    cogoToast.error('Ошибка при обновлении пользователя', {
                        position: 'top-right',
                        hideAfter: 4,
                    });
                });
        },
        [id, dispatch]
    );

    const onDeleteAppUser = useCallback(() => {
        dispatch(deleteAppUser(id))
            .then(() => {
                cogoToast.success('Пользователь удален', {
                    position: 'top-right',
                    hideAfter: 5,
                });
                history.push(`/dashboard/app-users`);
            })
            .catch(() => {
                cogoToast.error('Ошибка при удалении пользователя', {
                    position: 'top-right',
                    hideAfter: 4,
                });
            });
    }, [dispatch, id]);

    const goToAppUserStartBooking = () => {
        history.push(`/dashboard/app-user-start-booking/${appUser._id}`);
    };

    const goToAppUserBookings = () => {
        history.push(`/dashboard/bookings`, {
            appUserId: appUser._id,
        });
    };

    const goToInvaitedUsers = () => {
        history.push(`/dashboard/app-users`, {
            appUserId: appUser._id,
        });
    };

    if (!appUser) {
        return null;
    }

    const initialValues = {
        appUserId: appUser._id,
        createdAt: moment(appUser.createdAt).format(DATE_TIME_FORMAT),
        firstName: appUser.firstName,
        lastName: appUser.lastName,
        email: appUser.email,
        phoneNumber: appUser.phoneNumber,
        sex: appUser.sex,
        invitationCode: appUser.invitationCode,
        usedInvitationCode: appUser.usedInvitationCode,
        invitedUsers: appUser.invitedUsers.length,
        paymentDataId: appUser.paymentDataId,
        bonusPoints: appUser.bonusPoints,
        applicationVersion: appUser.applicationVersion,
        bookings: appUserBookings?.length ? appUserBookings.length : 0,
        disabled: appUser.disabled,
        comment: appUser.comment,
        deviceRegistrationsCount: String(appUser.deviceRegistrationsCount ?? 0),
        phoneRegistrationsCount: String(appUser.phoneRegistrationsCount ?? 0),
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BackButton
                    to="/dashboard/app-users"
                    title="К списку пользователей"
                    className={styles.backBtn}
                />
                <div className={styles.box}>
                    <AppUserForm
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                        onDeleteManager={onDeleteAppUser}
                        goToAppUserBookings={goToAppUserBookings}
                        goToAppUserStartBooking={goToAppUserStartBooking}
                        goToInvaitedUsers={goToInvaitedUsers}
                    />
                </div>
            </div>
        </div>
    );
};

export default AppUserDetails;
