import { AnyAction } from 'redux';

import { Awaited } from '@redux/types';
import { AppUser } from '@t/app-user';

import {
    FETCH_APP_USERS_SUCCESS,
    AppUserState,
    FETCH_APP_USER_SUCCESS,
    FETCH_APP_USERS_STATISTICS_SUCCESS,
    FILTER_APP_USERS_SUCCESS,
} from './types';
import { fetchAppUserData } from './actions';

const initialState: AppUserState = {
    ids: [],
    byId: {},
    bookingsIds: [],
    bookingsByUserId: {},
    invitedUsersByUserId: {},
    users: 0,
    registeredLastWeek: 0,
    usersHaveDoneOneWorkout: 0,
    usersIOS: 0,
    usersAndroid: 0,
    dailyAppUsers: [],
    numOfFilterAppUsers: 0,
    lastUpdated: 0,
};

export function appUserReducer(
    state = initialState,
    action: AnyAction
): AppUserState {
    if (action.type === FETCH_APP_USER_SUCCESS) {
        const { appUser, bookings, invitedUsers } = action.payload as Awaited<
            ReturnType<typeof fetchAppUserData>
        >;

        const included = state.ids.includes(appUser._id);
        const ids = included ? state.ids : [...state.ids, appUser._id];
        const byId = {
            ...state.byId,
            [appUser._id]: appUser,
        };
        const bookingsByUserId = {
            [appUser._id]: bookings,
        };

        const invitedUsersByUserId = {
            ...state.invitedUsersByUserId,
            [appUser._id]: state.invitedUsersByUserId[appUser._id]
                ? [...state.invitedUsersByUserId[appUser._id], ...invitedUsers]
                : invitedUsers,
        };

        return {
            ...state,
            ids,
            byId,
            bookingsByUserId,
            invitedUsersByUserId,
        };
    }

    if (action.type === FETCH_APP_USERS_SUCCESS) {
        const { appUsers } = action.payload;
        const ids = appUsers.map((appUser: AppUser) => appUser._id);
        const byId = appUsers.reduce(
            (total: { [id: string]: AppUser }, appUser: AppUser) => ({
                ...total,
                [appUser._id]: appUser,
            }),
            {}
        );

        return {
            ...state,
            ids,
            byId,
        };
    }

    if (action.type === FETCH_APP_USERS_STATISTICS_SUCCESS) {
        const {
            users,
            registeredLastWeek,
            usersHaveDoneOneWorkout,
            usersIOS,
            usersAndroid,
        } = action.payload;

        const lastUpdated = Date.now();
        return {
            ...state,
            users,
            registeredLastWeek,
            usersHaveDoneOneWorkout,
            usersIOS,
            usersAndroid,
            lastUpdated,
        };
    }

    if (action.type === FILTER_APP_USERS_SUCCESS) {
        const { dailyAppUsers, numOfFilterAppUsers } = action.payload;

        return {
            ...state,
            dailyAppUsers,
            numOfFilterAppUsers,
        };
    }

    return state;
}
