import axios from 'axios';

import { AppUser } from '@t/app-user';
import { Booking } from '@t/booking';
import { B2B_API_URL } from '@config';
import { AsyncActionCreator } from '@redux/types';
import { AppUserFormData } from '@containers/Dashboard/AppUserDetails/Form';

import {
    FETCH_APP_USERS_REQUEST,
    FETCH_APP_USERS_SUCCESS,
    FETCH_APP_USERS_ERROR,
    FETCH_APP_USER_REQUEST,
    FETCH_APP_USER_SUCCESS,
    FETCH_APP_USER_ERROR,
    DELETE_APP_USER_REQUEST,
    DELETE_APP_USER_SUCCESS,
    DELETE_APP_USER_ERROR,
    UPDATE_APP_USER_REQUEST,
    UPDATE_APP_USER_SUCCESS,
    UPDATE_APP_USER_ERROR,
    FETCH_APP_USERS_STATISTICS_REQUEST,
    FETCH_APP_USERS_STATISTICS_SUCCESS,
    FETCH_APP_USERS_STATISTICS_ERROR,
    FILTER_APP_USERS_REQUEST,
    FILTER_APP_USERS_SUCCESS,
    FILTER_APP_USERS_ERROR,
} from './types';

export const fetchAppUsers: AsyncActionCreator<string | null, AppUser[]> = (
    values?: string | null
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_APP_USERS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const { data: appUsers } = await axios.get(`${B2B_API_URL}/app-users`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                values,
            },
        });

        dispatch({
            type: FETCH_APP_USERS_SUCCESS,
            payload: {
                appUsers,
            },
        });

        return appUsers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_APP_USERS_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAppUserData = async ({
    userId,
    token,
}: {
    userId?: string;
    token: string;
}) => {
    const { data } = await axios.get<{
        appUser: AppUser;
        bookings: Booking[];
        invitedUsers: AppUser[];
    }>(`${B2B_API_URL}/app-users/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return data;
};
export const fetchAppUser: AsyncActionCreator<string, AppUser | undefined> = (
    userId?: string
) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_APP_USER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const data = await fetchAppUserData({ userId, token });

        dispatch({
            type: FETCH_APP_USER_SUCCESS,
            payload: data,
        });

        return data.appUser;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_APP_USER_ERROR,
            });

            throw err;
        }
    }
};

export const deleteAppUser: AsyncActionCreator<string> = (id) => async (
    dispatch,
    getState
) => {
    dispatch({
        type: DELETE_APP_USER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data: appUser } = await axios.delete(
            `${B2B_API_URL}/app-users/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: DELETE_APP_USER_SUCCESS,
            payload: { appUser },
        });

        return appUser;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: DELETE_APP_USER_ERROR,
            });

            throw err;
        }
    }
};

export const updateAppUser: AsyncActionCreator<
    { id: string; values: AppUserFormData },
    AppUser
> = ({ id, values }) => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_APP_USER_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    const newValues = { ...values };

    try {
        const { data: appUser } = await axios.put(
            `${B2B_API_URL}/app-users/${id}`,
            newValues,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: UPDATE_APP_USER_SUCCESS,
            payload: appUser,
        });

        return appUser;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: UPDATE_APP_USER_ERROR,
            });

            throw err;
        }
    }
};

export const fetchAppUserStatistics: AsyncActionCreator<
    string | null | void
> = () => async (dispatch, getState) => {
    dispatch({
        type: FETCH_APP_USERS_STATISTICS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();

    try {
        const { data } = await axios.get(`${B2B_API_URL}/app-users/stat`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        dispatch({
            type: FETCH_APP_USERS_STATISTICS_SUCCESS,
            payload: {
                users: data.users.toString(),
                registeredLastWeek: data.registeredLastWeek.toString(),
                usersHaveDoneOneWorkout: data.usersHaveDoneOneWorkout,
                usersIOS: data.usersIOS.toString(),
                usersAndroid: data.usersAndroid.toString(),
            },
        });

        return data.users;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FETCH_APP_USERS_STATISTICS_ERROR,
            });

            throw err;
        }
    }
};

export const filterAppUsers: AsyncActionCreator<
    {
        startDate: string;
        endDate: string;
        offset: number;
    },
    { date: Date; appUsers: number }[]
> = ({ startDate, endDate, offset }) => async (dispatch, getState) => {
    dispatch({
        type: FILTER_APP_USERS_REQUEST,
    });

    const {
        auth: { token },
    } = getState();
    try {
        const {
            data: { dailyAppUsers, totalAppUsers },
        } = await axios.post(
            `${B2B_API_URL}/app-users/daily-stat`,
            { startDate, endDate, offset },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: FILTER_APP_USERS_SUCCESS,
            payload: {
                dailyAppUsers,
                numOfFilterAppUsers: totalAppUsers,
            },
        });

        return dailyAppUsers;
    } catch (err) {
        if (err instanceof Error) {
            dispatch({
                type: FILTER_APP_USERS_ERROR,
            });

            throw err;
        }
    }
};
